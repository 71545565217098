import { Subscription } from 'rxjs'
import { Component, OnDestroy, OnInit } from '@angular/core'
import { Report } from './report.model'
import { ActivatedRoute, Router } from '@angular/router'
import { ReportService } from './report.service'
import { JhiAlertService, JhiEventManager } from 'ng-jhipster'
import { BaseReportComponent } from './report-base.component'
import { ReportType } from './report-types.model'

@Component({
    selector: 'jhi-materials-by-component-type-equals-extended',
    templateUrl: './report-materials-by-component-type-equals-extended-dialog.html'
})
export class ReportMaterialsByComponentTypeEqualsExtendedComponent extends BaseReportComponent implements OnInit, OnDestroy {
    eventSubscriber: Subscription
    report: Report

    constructor(
        protected readonly route: ActivatedRoute,
        protected readonly router: Router,
        private readonly eventManager: JhiEventManager,
        protected readonly jhiAlertService: JhiAlertService,
        protected readonly reportsService: ReportService) {
        super(route, router, reportsService, jhiAlertService)
    }

    ngOnInit() {
        this.reportType = ReportType.MATERIALS_BY_COMPONENT_TYPE_EQUALS_EXTENDED
        super.ngOnInit()

        this.registerChangeInReport()
    }

    onSaveSuccess(result: Report) {
        this.report = result
        if (this.isNew) {
            this.router.navigate(['/report/materialsByComponentTypeEqualsExtended/' + result.id + '/edit'])
        }
    }

    registerChangeInReport() {
        this.eventSubscriber = this.eventManager.subscribe('reportDeleted', (response) => this.goToReportListPage())
    }

    ngOnDestroy() {
        this.componentDestroyed.next(true)
        this.componentDestroyed.complete()
        this.cancelledPolling.next(true)
        this.cancelledPolling.complete()
    }
}
