import { Component } from '@angular/core'

@Component({
    selector: 'jhi-footer',
    template: `
        <footer class="footer text-center">
            <div><jhi-app-version> </jhi-app-version></div>
        </footer>
    `,
})
export class FooterComponent {}
