import { Component, OnDestroy, OnInit } from '@angular/core'
import { Subject, Subscription } from 'rxjs'
import { ITEMS_PER_PAGE } from '@app/shared'
import { BaseListComponent } from '@app/shared/list/base-list.component'
import { ActivatedRoute, Router } from '@angular/router'
import { take, takeUntil } from 'rxjs/operators'
import { Change, Changelog, ChangelogService, ChangeService, Translation } from '@app/data-access'
import { JhiParseLinks } from 'ng-jhipster'
import { OAuth2Service, Roles, StateStorageService } from '@app/auth'
import { ProfileEnvironmentEnum } from '@app/layouts'

@Component({
    selector: 'jhi-change',
    templateUrl: './change.component.html',
    styleUrls: ['./change.component.scss']
})
export class ChangeComponent extends BaseListComponent implements OnInit, OnDestroy {

    private readonly componentDestroyed: Subject<boolean> = new Subject()

    changes: Change[]

    eventSubscriber: Subscription

    itemsPerPage: number

    page: number

    links: any

    changelogId: number

    changelog: Changelog

    totalItems: number

    predicate: any

    reverse: any

    isLidlEnv: boolean

    isIntEditor: boolean

    hasEditPermissions: boolean

    constructor(private readonly changeService: ChangeService,
                private readonly changelogService: ChangelogService,
                private readonly oAuth2Service: OAuth2Service,
                private readonly stateStorageService: StateStorageService,
                readonly route: ActivatedRoute,
                readonly router: Router,
                private readonly parseLinks: JhiParseLinks, ) {
        super(router, route)
        this.itemsPerPage = ITEMS_PER_PAGE
        this.page = 0
        this.links = {
            last: 0
        }
        this.predicate = 'id'
        this.reverse = true
    }

    reset() {
        this.clear()
        this.loadAll()
    }

    private clear() {
        this.page = 0
        this.changes = []
    }

    ngOnDestroy(): void {
        this.componentDestroyed.next(true)
        this.componentDestroyed.complete()
    }

    ngOnInit(): void {
        super.ngOnInit()
        this.route.params.pipe(takeUntil(this.componentDestroyed))
            .subscribe((params) => {
                if (params['changelogId']) {
                    this.changelogId = params['changelogId']
                    this.loadAll(false)
                }
            })
    }

    loadAll(append?: boolean) {
        this.isInSearchMode = false
        if (!append) {
            this.clear()
        }
        if (!this.changelogId){
            return
        }

        this.changeService.findAllByChangelogId(this.changelogId, {
            page: this.page,
            size: this.itemsPerPage,
            sort: this.sort()
        }).pipe(
            takeUntil(this.componentDestroyed))
            .subscribe(
                (res) => this.onSuccess(res.json, res.headers, append)
            )

        this.changelogService.findById(this.changelogId)
            .pipe(takeUntil(this.componentDestroyed))
            .subscribe(
                (res) => {
                    this.changelog = res
                    this.setPermissions()
                }
            )
    }

    private onSuccess(data, headers, append?) {
        this.links = this.parseLinks.parse(headers.get('link'))
        this.totalItems = headers.get('X-Total-Count')
        append ? data.forEach((tag) => this.changes.push(tag)) : this.changes = data
    }

    loadPage(page) {
        if (page > this.links.last) {
            return
        }
        this.page = page
        const append = true
        this.loadAll(append)
    }

    trackId(item: Change) {
        return item.id
    }

    sort() {
        return [this.predicate + ',' + (this.reverse ? 'asc' : 'desc')]
    }

    search(append?: boolean) {
    }

    getDefaultShortDescription(change: Change): Translation {
        if (this.isLidlEnv) {
            return change.shortDescription.translationForCountryCode(change.changelog.createCountry.code)
        }
        else {
            return change.shortDescription.defaultTranslation
        }

    }

    private setPermissions() {
        this.oAuth2Service.hasAnyAuthority(Roles.INT_EDITOR_AND_PUBLISHER).pipe(take(1)).subscribe((isIntEditorOrPublisher: boolean) => {
            this.isLidlEnv = this.stateStorageService.getProfileEnvironment() === ProfileEnvironmentEnum.Lidl
            this.isIntEditor = isIntEditorOrPublisher
            this.hasEditPermissions =
            isIntEditorOrPublisher ||
            (!!this.changelog.createCountry?.code && this.changelog.createCountry.code?.toLowerCase() === this.stateStorageService.getSessionUserCountryCode())
        })
    }

}
