import { inject, Injectable } from '@angular/core';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

export interface ModalOptions extends NgbModalOptions {
    /**
     * If `true`, the backdrop element will be created for a given modal.
     *
     * Alternatively, specify `'static'` for a backdrop which doesn't close the modal on click.
     *
     * Default value is `true`.
     */
    backdrop?: boolean | 'static';
    /**
    * If `true`, the modal will be centered vertically.
    *
    * Default value is `false`.
    *
    */
    centered?: boolean;
    /**
     * Size of a new modal window.
     */
    size?: 'sm' | 'lg' | 'xl';
    /**
     * A custom class to append to the modal window.
     */
    /**
     * Properties to inject inside the component
     */
    properties?: any;
}

@Injectable({ providedIn: 'root' })
export class ModalService {
    private readonly ngbModal = inject(NgbModal);
    private readonly modalOptions: ModalOptions = {
        backdrop: 'static',
        size: 'lg',
        centered: false
    }
    public open<T, K = any>(component: any, options?: Partial<ModalOptions>): Promise<T | K | undefined> {
        return new Promise<T | K | undefined>((resolve: (value: T | undefined) => void, reject: (reason: K | undefined) => void): void => {
            const ngbModal: NgbModalRef = this.ngbModal.open(component, { ...this.modalOptions, ...options });
            this.updatePropertiesInComponent(ngbModal.componentInstance, options?.properties);

            ngbModal.result.then((value: T | undefined): void => resolve(value)
                , (reason: K | undefined): void => reject(reason)
            )
        });
    }

    private updatePropertiesInComponent(componentInstance: any, properties: any): void {
        if (!properties) return;

        for (const key in properties) {
            if (properties.hasOwnProperty(key)) {
                componentInstance[key] = properties[key];
            }
        }
    }
}