import { BaseEntity } from '../common'
import { ChangelogStatus } from './changelog-status.model'
import { BuildingType } from '../building-type'
import { Country } from '../country'

export class Changelog implements BaseEntity {
    constructor(
        public id?: number,
        public name?: string,
        public status?: ChangelogStatus,
        public buildingType?: BuildingType,
        public createCountry?: Country,
        public version?: number,
        public createdAt?: string,
        public updatedAt?: string,
        public deletedAt?: string
    ) {
    }
}
