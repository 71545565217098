<form name="deleteForm" (ngSubmit)="confirmDelete(data?.id)">
    <div class="modal-header">
        <h4 class="modal-title" jhiTranslate="entity.delete.title">Confirm delete operation</h4>
        <button type="button" class="close" data-dismiss="modal" (click)="cancel()"></button>
    </div>
    <div class="modal-body">

        <p [jhiTranslate]="message" [translateValues]="{ id: data?.id, label: data?.name }">Are you sure you want to
            delete?</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="cancel()">
            <fa-icon icon="ban"></fa-icon>&nbsp;<span jhiTranslate="entity.action.cancel">Cancel</span>
        </button>
        <button type="submit" class="btn btn-danger">
            <fa-icon icon="times"></fa-icon>&nbsp;<span jhiTranslate="entity.action.delete">Delete</span>
        </button>
    </div>
</form>