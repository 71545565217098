import { Injectable, InjectionToken } from '@angular/core'
import { Observable } from 'rxjs'
import { ResponseWrapper } from '../common'

export const EntityServiceInjectionToken = new InjectionToken<EntityService<any>>('EntityService');

@Injectable()
export abstract class EntityService <T> {

    /**
     * Performs a request to create an entity
     */
    abstract create(entity: T): Observable<T>

    /**
     * Performs a request to update an already existing entity
     */
    abstract update(entity: T): Observable<T>

    /**
     * Retrieves an entity via an id
     */
    abstract find(id: number): Observable<T>

    /**
     * Queries the database by a specific criteria
     * @param showDeactivated - include deleted entries
     * @param req - the query criteria
     */
    abstract query(showDeactivated?: boolean, req?: any): Observable<ResponseWrapper>

    /**
     * Performs a soft-delete request for an entity with a given id
     */
    abstract delete(id: number): Observable<any>

    /**
     * Queries SearchEngine by a specific criteria
     * @param query - a valid searchengine query
     * @param req - the query criteria
     */
    abstract search(query: string, req?: any): Observable<ResponseWrapper>

    /**
     * Queries SearchEngine by a given search term
     * @param term - the term to search an object by
     * @param req - the query criteria
     */
    abstract searchAutocomplete(term: string, req?: any): Observable<ResponseWrapper>
}
