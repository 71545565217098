<form name="editForm" role="form" novalidate (ngSubmit)="save()" #editForm="ngForm" *ngIf="hasLoaded">




    <div class="row">
        <div class="col-md-10">

            <h2 class="modal-title change-metadata" id="myLanguageLabel" jhiTranslate="kstandards.change.home.createOrEditLabel">
                Create or edit a change</h2>

            <p *ngIf="change.deletedAt">
                <span class="badge badge-warning" jhiTranslate="entity.isDeactivated" [translateValues]="{deletedAt: change.deletedAt}">Deactivated since {{ change.deletedAt }}</span>
            </p>

            <div class="form-group" [hidden]="!change.id">
                <label for="id" jhiTranslate="global.field.id">ID</label>
                <input type="text" class="form-control" id="id" name="id" [(ngModel)]="change.id" readonly="readonly">
            </div>
            <div class="change-metadata" *ngIf="change.changelog">
                <label class="form-control-label" jhiTranslate="kstandards.changelog.home.title" for="changelog_name">Responsible Editor</label>
                <input type="text" class="form-control input-upper" id="changelog_name" name="changelog_name" [(ngModel)]="change.changelog.name" readonly="readonly">
            </div>
            <div class="form-group">
                <label class="col-form-label" jhiTranslate="kstandards.content.materialCategory">Material Category</label>
                <div>
                    <select class="form-control" name="materialCategory" [(ngModel)]="change.shortDescription.materialCategory" id="field_content_materialCategory" [disabled]="!hasEditPermissions">
                        <option jhiTranslate="kstandards.content.optionNone" [ngValue]="null"></option>
                        <option *ngFor="let materialCategory of materialCategories" [ngValue]="materialCategory.id === change.shortDescription.materialCategory?.id ? change.shortDescription.materialCategory : materialCategory">{{ materialCategory.name }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="form-group">
                <label jhiTranslate="kstandards.change.home.shortDescriptionLabel">Content translations</label>
                <single-content-edit-area name="content" [languages]="languages" [displayAll]="false" [userCountryCode]="userCountryCode" [isIntEditor]="isIntEditor" [createCountryCode]="change.changelog.createCountry.code.toLowerCase()" [translationWorkflowButton]="isIntEditor" [(ngModel)]="change.shortDescription"></single-content-edit-area>
            </div>
            <div class="form-group">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" [(ngModel)]="change.booklet" name="booklet" id="field_booklet" [disabled]="!hasEditPermissions">
                    <label class="form-control-label" jhiTranslate="kstandards.change.booklet" for="field_booklet">Booklet</label>
                </div>
            </div>
            <div class="select-dropdown change-metadata">
                <label for="field_type" class="form-control-label">{{'kstandards.change.type.title' | translate}}</label>
                <select class="form-control custom-select" id="field_type" name="type" [(ngModel)]="change.type" required [disabled]="!hasEditPermissions">
                    <option [ngValue]="null"></option>
                    <option [ngValue]="changeType" *ngFor="let changeType of changeTypes;">
                        {{'kstandards.change.type.'+changeType | translate}}
                    </option>
                </select>
            </div>
            <div class="select-dropdown change-metadata">
                <label for="field_constructionType" class="form-control-label">{{'kstandards.changelog.constructionType' | translate}}</label>
                <select class="form-control custom-select" id="field_constructionType" name="constructionType" [(ngModel)]="change.constructionType" required [disabled]="!hasEditPermissions">
                    <option [ngValue]="null"></option>
                    <option [ngValue]="constructionType" *ngFor="let constructionType of constructionTypes;">
                        {{'kstandards.ConstructionType.'+constructionType | translate}}
                    </option>
                </select>
            </div>
            <div class="select-dropdown change-metadata" *ngIf="countries">
                <label for="field_proposingCountry" class="form-control-label">{{'kstandards.change.proposingCountry' | translate}}</label>
                <select class="form-control custom-select" id="field_proposingCountry" name="proposingCountry" [(ngModel)]="change.proposingCountry" [disabled]="!hasEditPermissions">
                    <option [ngValue]="null" *ngIf="isIntEditor"></option>
                    <ng-container *ngFor="let country of countries;">
                        <option *ngIf="isIntEditor || (country?.code === userCountryCode)" [ngValue]="country.id === change.proposingCountry?.id ? change.proposingCountry : country">
                            {{country.name}}
                        </option>
                    </ng-container>
                </select>
            </div>
            <div class="change-metadata">
                <label class="form-control-label" jhiTranslate="kstandards.change.currentStatusDescription" for="field_currentStatusDescription">Current Status</label>
                <input type="text" class="form-control" name="currentStatusDescription" id="field_currentStatusDescription" [(ngModel)]="change.currentStatusDescription" required [disabled]="!hasEditPermissions">
            </div>
            <div class="change-metadata">
                <label class="form-control-label" jhiTranslate="kstandards.change.responsibleEditor" for="field_responsibleEditor">Responsible Editor</label>
                <input type="text" class="form-control" name="responsibleEditor" id="field_responsibleEditor" [(ngModel)]="change.responsibleEditor" required [disabled]="!hasEditPermissions">
            </div>
            <div class="change-metadata">
                <label class="form-control-label" jhiTranslate="kstandards.change.source" for="field_source">Source</label>
                <input type="text" class="form-control" name="source" id="field_source" [(ngModel)]="change.source" [disabled]="!hasEditPermissions">
            </div>
            <div>
                <div class="form-group">
                    <button *ngIf="!change.deletedAt && isIntEditor" (click)="addAllCountries()" type="button" replaceUrl="true" jhiTranslate="kstandards.change.home.addAllCountries" class="btn btn-secondary rel-utility-button">Add All
                    </button>
                </div>
            <div class="form-group">
                <label class="form-control-label" jhiTranslate="kstandards.multisearch.country">
                    Countries
                </label>
                <ng-select [items]="isIntEditor ? countries : [change.changelog?.createCountry]" bindLabel="name" name="countries" [addTag]="false" [multiple]="true" [(ngModel)]="change.countries" [hideSelected]="true" [readonly]="!hasEditPermissions" required>
                </ng-select>
            </div>

            </div>
            <multi-search (onAdded)="changeEntityParentService.addParentEntities($event, this.change)" name="material-search" [searchFields]="['name', 'label']" [(ngModel)]="change.materials" [service]="materialService" entityName="material" [readonly]="!hasEditPermissions">
            </multi-search>

            <multi-search name="room-search" [searchFields]="['name', 'number']" [(ngModel)]="change.rooms" [service]="roomService" entityName="room" [readonly]="!hasEditPermissions"></multi-search>

            <multi-search name="craft-search" [searchFields]="['name']" [(ngModel)]="change.crafts" [service]="craftService" entityName="craft" [readonly]="!hasEditPermissions"></multi-search>

            <multi-search (onAdded)="changeEntityParentService.addParentEntities($event, this.change)" name="material-group-search" [searchFields]="['name']" [(ngModel)]="change.materialGroups" [service]="materialGroupService" entityName="materialGroup" [readonly]="!hasEditPermissions">
            </multi-search>

            <multi-search (onAdded)="changeEntityParentService.addParentEntities($event, this.change)" name="material-sub-group-search" [searchFields]="['name']" [(ngModel)]="change.materialSubGroups" [service]="materialSubGroupService" entityName="materialSubGroup" [readonly]="!hasEditPermissions">
            </multi-search>
        </div>
        <div class="col-md-2 global-buttons">
            <div class="form-group">
                <button type="submit" [disabled]="editForm.form.invalid" class="btn btn-special">
                    <fa-icon icon="save"></fa-icon>&nbsp;<span jhiTranslate="entity.action.save">Save</span>
                </button>
            </div>
            <div class="form-group" *ngIf="!isCopy && !isNew">
                <button (click)="copyChange()" [disabled]="editForm.form.invalid" class="btn btn-secondary" type="button">
                    <icon shape="duplicate"></icon>&nbsp;<span jhiTranslate="entity.action.copy">Copy Change</span>
                </button>
            </div>
            <div class="form-group" *ngIf="!isNew">
                <button *ngIf="!change.deletedAt" type="button" [routerLink]="['/', { outlets: { popup: 'change/'+change.id + '/delete'} }]" replaceUrl="true" class="btn btn-danger" [disabled]="!hasEditPermissions" [ngbTooltip]="(!hasEditPermissions ? 'kstandards.change.detail.deleteNotAllowed' : '')  | translate">
                    <fa-icon icon="times"></fa-icon>
                    <span class="d-none d-md-inline" jhiTranslate="entity.action.delete">Delete</span>
                </button>
            </div>
            <div class="form-group">
                <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="cancel()">
                    <fa-icon icon="ban"></fa-icon>&nbsp;<span jhiTranslate="entity.action.cancel">Cancel</span>
                </button>
            </div>
        </div>
    </div>
</form>
