import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BaseEntity, EntityService, EntityServiceInjectionToken } from '@app/data-access';
import { DeleteDialogComponent } from '@app/shared/components/delete-dialog/delete-dialog.component';
import { JhiEventManager, JhiEventWithContent } from 'ng-jhipster';
import { ModalOptions, ModalService } from '../modal/modal.service';


export interface DeleteModalOptions {
    id: number;
    message: string;
    event: JhiEventWithContent<any> | string;
    component?: any;
}

@Injectable()
export class DeleteModalService<T extends BaseEntity> {
    private readonly modalService = inject(ModalService);
    private readonly router: Router = inject(Router);
    private readonly entityService: EntityService<T> = inject(EntityServiceInjectionToken);
    private readonly eventManager: JhiEventManager = inject(JhiEventManager);
    private readonly deleteModalOptionsDefault: Partial<DeleteModalOptions> = {
        component: DeleteDialogComponent
    };
    private isOpen: boolean = false;

    public open(deleteModalOptions: DeleteModalOptions): Promise<void> {
        return new Promise<void>((resolve: () => void, reject: (reason: any) => void): void => {
            const deleteModalOptionsUpdated: DeleteModalOptions = { ...this.deleteModalOptionsDefault, ...deleteModalOptions };

            if (!this.canDelete(deleteModalOptionsUpdated)) return;
            this.isOpen = true;
            this.entityService.find(deleteModalOptionsUpdated?.id).subscribe((value: T): void => {
                if (!value) return resolve();

                const modalOptions: ModalOptions = {
                    properties: { data: value, message: deleteModalOptionsUpdated.message }
                };
                this.buildingTypeCategoryModalRef(deleteModalOptionsUpdated, modalOptions)
                    .then((): void => {
                        this.isOpen = false;
                        resolve()
                    }, (): void => {
                        this.isOpen = false;
                        reject(new Error('dismiss'));
                    });
            })
        })
    }

    private canDelete(deleteModalOptions: DeleteModalOptions): boolean {
        return (
            !this.isOpen &&
            deleteModalOptions?.message &&
            deleteModalOptions?.event &&
            deleteModalOptions?.id !== null &&
            deleteModalOptions?.id !== undefined
        );
    }

    private async buildingTypeCategoryModalRef(deleteModalOptions: DeleteModalOptions, modalOptions: ModalOptions): Promise<void> {
        const id: number = await this.modalService.open<number>(deleteModalOptions.component, modalOptions);
        if (!id) return;

        await this.entityService.delete(id).toPromise();
        this.eventManager.broadcast(deleteModalOptions.event);
        this.router.navigate([{ outlets: { popup: null } }], { replaceUrl: true });
    }
}