import { Component, OnInit } from '@angular/core'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { Change, Changelog, ChangelogService } from '@app/data-access'
import { Router } from '@angular/router'
import { OAuth2Service, Roles, StateStorageService } from '@app/auth'
import { take } from 'rxjs/operators'

@Component({
    selector: 'jhi-change-copy-dialog',
    styleUrls: ['./change-copy-dialog.component.scss'],
    templateUrl: './change-copy-dialog.component.html'
})
export class ChangeCopyDialogComponent implements OnInit{

    change: Change

    changelogs: Changelog[]

    constructor(
        public activeModal: NgbActiveModal,
        public changelogService: ChangelogService,
        public router: Router,
        private readonly oAuth2Service: OAuth2Service,
        private readonly stateStorageService: StateStorageService,
    ) {
    }

    changelogSelected(changelog: Changelog): void{
        setTimeout(() => {
            this.router.navigate([`/changelog/${changelog.id}/change-new`],
                { queryParams: { sourceChangeId: this.change.id } })
            }, 0)
        this.clear()
    }

    ngOnInit(): void {
        this.changelogService.query(true, false, {sort: ['id,desc']})
            .subscribe((changelogs) => {
                this.changelogs = changelogs.json
                this.setPermissions()
            })
    }

    getQuery() {
        return `NOT id :(${this.change.changelog?.id}) AND status :(OPEN)`
    }

    clear() {
        this.activeModal.dismiss('cancel')
    }

    private setPermissions() {
        this.oAuth2Service.hasAnyAuthority(Roles.INT_EDITOR_AND_PUBLISHER).pipe(take(1)).subscribe((isIntEditorOrPublisher: boolean) => {
            if (!isIntEditorOrPublisher){
                const userCountryCode = this.stateStorageService.getSessionUserCountryCode()
                this.filterChangelogs(userCountryCode)
            }
        })
    }

    private filterChangelogs(userCountryCode: string){
        this.changelogs = this.changelogs.filter((changelog) =>
            changelog.createCountry.code?.toLowerCase() === userCountryCode
        )
    }
}
