import { Component, forwardRef, Input } from '@angular/core'
import { SingleContentEditComponent } from './single-content-edit.component'
import { NG_VALUE_ACCESSOR } from '@angular/forms'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { CountryUtil } from '@app/util/country.util'
import { Language, Translation } from '@app/data-access'
import { CountryIdEnum, StateStorageService } from '@app/auth'
import { DefaultLocaleSingleton } from '@app/util/default-locale.singleton'

@Component({
    selector: 'single-content-edit-area',
    templateUrl: 'single-content-edit-area.component.html',
    styleUrls: ['single-content-edit.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SingleContentEditAreaComponent),
            multi: true
        }
    ]
})
export class SingleContentEditAreaComponent extends SingleContentEditComponent{
    public CountryIdEnum = CountryIdEnum

    MATERIAL_CATEGORY_ICON = '<[MATERIAL_CATEGORY_ICON]>'

    @Input()
    public displayAll = true

    public isShowingAll = false

    switchMode() {
        this.isShowingAll = !this.isShowingAll
    }

    public constructor(
        readonly modalService: NgbModal,
        readonly stateStorageService: StateStorageService
    ) {
        super(modalService, stateStorageService)
    }

    insertMaterialCategory(id){
        const textAreaElement = document.getElementById(id) as HTMLTextAreaElement
        const currentValue = textAreaElement.value
        const start = textAreaElement.selectionStart
        const end = textAreaElement.selectionEnd
        textAreaElement.value = currentValue.substring(0, start) + this.MATERIAL_CATEGORY_ICON + currentValue.substring(end)
        textAreaElement.dispatchEvent(new Event('input'))
        return false
    }

    getTranslationsForLanguages() {
        if (this.displayAll) {
            return this.content.translations.filter(
                translation => this.languageIds.has(translation.language.id)
            )
        }
        if (this.isShowingAll) {
            return this.content.translations.filter(
                translation => this.languageIds.has(translation.language.id)
            )
        }
        if (!this.isLidlEnv) {
            return this.filterTranslationsByCountryCode(CountryUtil.getCodeFromLocale(DefaultLocaleSingleton.instance.defaultLocale).toLowerCase())
        }
        else if (this.userCountryCode === CountryIdEnum.International) {
            return this.filterTranslationsByCountryCode(CountryIdEnum.International)
        }
        else if (this.hasEditPermissionForOwnLanguage) {
            return this.filterTranslationsByCountryCode(this.userCountryCode)
        }
        else {
            return this.filterTranslationsByCountryCode(this.createCountryCode)
        }
    }

    // country editors can only edit their own language
    hasEditPermissionForLanguage(language: Language): boolean {
        return !this.isLidlEnv || this.userCountryCode === CountryIdEnum.International
                    || (this.hasEditPermissionForOwnLanguage && CountryUtil.getCodeFromLocale(language.locale).toLowerCase() === this.userCountryCode)
    }

    private filterTranslationsByCountryCode(countryCode: string): Translation[] {
        return this.content.translations.filter(
            translation =>
                this.languageIds.has(translation.language.id) &&
                CountryUtil.getCodeFromLocale(translation.language.locale).toLowerCase() === countryCode
        )
    }
}
