export enum ReportType {
    ROOMS_BY_BUILDING_TYPE = 'ROOMS_BY_BUILDING_TYPE',
    ROOMS_WITH_MATERIALS  = 'ROOMS_WITH_MATERIALS',
    MATERIALS_BY_BUILDING_TYPE= 'MATERIALS_BY_BUILDING_TYPE',
    PRODUCTS_BY_MANUFACTURER = 'PRODUCTS_BY_MANUFACTURER',
    MATERIAL_VARIANTS = 'MATERIAL_VARIANTS',
    MATERIALS_AND_ROOMS_WITHOUT_IMAGES = 'MATERIALS_AND_ROOMS_WITHOUT_IMAGES',
    MATERIALS_BY_MATERIAL_CATEGORY = 'MATERIALS_BY_MATERIAL_CATEGORY',
    MATERIALS_BY_COMPONENT_TYPE_EQUALS_EXTENDED = 'MATERIALS_BY_COMPONENT_TYPE_EQUALS_EXTENDED'
}
