<div class="row" *ngIf="hasPreparedTranslations">
    <div [class.col-lg-6]="hasAttachmentPicker && !inlineAttachmentPicker" [class.col-lg-4]="!hasAttachmentPicker || inlineAttachmentPicker" class="input-group form-group" *ngFor="let translation of getTranslationsForLanguages(); let i = index">
        <label for="{{ name }}translation-{{ i }}-{{ translation.language.locale }}" class="input-group-prepend" title="{{ translation.language.name }}"><span class="input-group-text"><span class="flag-icon flag-icon-{{ FlagUtil.getFlagFromLocale(translation.language.locale) }}"></span></span></label>
        <input type="text" [disabled]="disabled || (isLidlEnv && userCountryCode != CountryIdEnum.International && CountryUtil.getCodeFromLocale(translation.language.locale).toLowerCase()!=userCountryCode)" (focus)="onTouched()" (input)="onChange()" class="form-control" id="{{ name }}translation-{{ i }}-{{ translation.language.locale }}" name="{{ name }}translation-{{ i }}-{{ translation.language.locale }}" [(ngModel)]="translation.textContent" [readonly]="readonly">
    </div>
    <div class="col-lg-4" *ngIf="!readonly && hasAttachmentPicker && inlineAttachmentPicker">
        <div [ngbTooltip]="(!hasEditPermissions ? 'kstandards.translationJob.addAttachmentNotAllowed' : '')  | translate" openDelay="500" style="width: 40px;">
            <button [disabled]="!hasEditPermissions" type="button" (click)="openAttachmentPicker()" class="btn btn-secondary" [ngbTooltip]="content.linkedAttachment ? content.linkedAttachment.name : null">
                <fa-icon icon="file"></fa-icon>
            </button>
        </div>
        <div [ngbTooltip]="(!hasEditPermissions ? 'kstandards.translationJob.removeAttachmentNotAllowed' : '')  | translate" openDelay="500" style="width: 40px;">
            <button [disabled]="!hasEditPermissions" type="button" *ngIf="content && content.linkedAttachment" (click)="removeAttachment()" class="btn btn-danger">
                <fa-icon icon="trash"></fa-icon>
            </button>
        </div>
    </div>
</div>
<div class="form-group" *ngIf="!readonly && hasPreparedTranslations && hasAttachmentPicker && !inlineAttachmentPicker">
    <div>
        <a [routerLink]="['/attachment', content.linkedAttachment.id, 'edit']" *ngIf="content.linkedAttachment">{{
            content.linkedAttachment.name }}</a>
        <div class="form-group" [ngbTooltip]="(!hasEditPermissions ? 'kstandards.translationJob.addAttachmentNotAllowed' : '')  | translate" openDelay="500" style="width: 184px">
            <button type="button" (click)="openAttachmentPicker()" class="btn btn-secondary" jhiTranslate="kstandards.content.chooseAttachment" [disabled]="!hasEditPermissions">Choose attachment
            </button>
        </div>
        <div class="form-group" [ngbTooltip]="(!hasEditPermissions ? 'kstandards.translationJob.removeAttachmentNotAllowed' : '')  | translate" openDelay="500" style="width: 184px">
            <button type="button" *ngIf="content && content.linkedAttachment" (click)="removeAttachment()" class="btn btn-danger" [disabled]="!hasEditPermissions">
                <icon class="icon-white" shape="trash"></icon>
                <span jhiTranslate="kstandards.content.removeAttachment"></span>
            </button>
        </div>
    </div>
</div>
<div *ngIf="!readonly && translationWorkflowButton && content?.id" class="form-group" [ngbTooltip]="(!hasEditPermissions ? 'kstandards.translationJob.createJobNotAllowed' : '')  | translate" openDelay="500" style="width: 184px">
    <button [disabled]="!hasEditPermissions" (click)="createTranslationJob()" class="btn btn-secondary" jhiTranslate="kstandards.content.translation.createJob" type="button">
    </button>
</div>