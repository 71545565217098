import { BaseEntity } from '../common'
import { Manufacturer } from '../manufacturer/manufacturer.model'
import { ProductProjectRelation } from './product-project-relation.model'
import { ProductGroup } from '../product-group'
import { Content } from '../content/content.model'
import { MaterialGroup } from '../material-group'
import { Material } from '../material'
import { ConstructionType } from '../common'
import { Country } from '../country'

export class Product implements BaseEntity {
    constructor(
        public id?: number,
        public order?: number,
        public name?: string,
        public translatableName?: Content,
        public constructionType?: ConstructionType,
        public description?: string,
        public series?: string,
        public color?: string,
        public measurement?: string,
        public slipResistance?: string,
        public articleDescription?: string,
        public manufacturer?: Manufacturer,
        public productGroup?: ProductGroup,
        public projects?: string[],
        public projectRelations?: ProductProjectRelation[],
        public materials?: Material[],
        public materialGroups?: MaterialGroup[],
        public createCountry?: Country,
        public createdAt?: string,
        public updatedAt?: string,
        public deletedAt?: string,
    ) {
        if (!projects) {
            this.projects = []
        }

        if (!projectRelations) {
            this.projectRelations = []
        }

        if (!this.translatableName) {
            this.translatableName = new Content()
        }
    }
}
