<div class="row" *ngIf="hasPreparedTranslations">

    <div [class.col-lg-6]="!hasAttachmentPicker" [class.col-lg-4]="hasAttachmentPicker" class="input-group form-group input-container" *ngFor="let translation of getTranslationsForLanguages(); let i = index">
        <label for="{{ name }}translation-{{ i }}-{{ translation.language.locale }}" class="input-group-prepend" title="{{ translation.language.name }}"><span class="input-group-text"><span class="flag-icon flag-icon-{{ FlagUtil.getFlagFromLocale(translation.language.locale) }}"></span></span></label>
        <textarea rows="4" class="non-resizable-area form-control" [disabled]="disabled" (focus)="onTouched()" (input)="onChange()" id="{{ name }}translation-{{ i }}-{{ translation.language.locale }}" name="{{ name }}translation-{{ i }}-{{ translation.language.locale }}" [(ngModel)]="translation.textContent" [disabled]="!hasEditPermissionForLanguage(translation.language)">
        </textarea>
        <button class="add-material-category-button" [disabled]="!content.materialCategory || !hasEditPermissionForLanguage(translation.language)" type="button" (click)="insertMaterialCategory(name + 'translation-' + i + '-' + translation.language.locale)">
            IMG
        </button>
    </div>
    <div class="col-lg-4" *ngIf="hasAttachmentPicker">
        <button type="button" (click)="openAttachmentPicker()" class="btn btn-secondary" [ngbTooltip]="content.linkedAttachment ? content.linkedAttachment.name : null">
            <fa-icon icon="file"></fa-icon>
        </button>
        <button type="button" *ngIf="content && content.linkedAttachment" (click)="removeAttachment()" class="btn btn-danger">
            <fa-icon icon="trash"></fa-icon>
        </button>
    </div>
</div>
<div class="row" *ngIf="!displayAll">
    <button type="button" (click)="switchMode()" class="btn btn-link expandable-list-collapse-link" [jhiTranslate]="isShowingAll ? 'global.expandable-list.hide':'global.expandable-list.more'"></button>
</div>
<div *ngIf="translationWorkflowButton && content?.id" class="form-group">
    <button (click)="createTranslationJob()" class="btn btn-secondary" jhiTranslate="kstandards.content.translation.createJob" type="button">
    </button>
</div>
