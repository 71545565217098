import { Routes } from '@angular/router'
import { Roles, UserRouteAccessService } from '@app/auth'
import { ReportDeletePopupComponent } from './report-delete-dialog.component'
import { ReportRoomsByBuildingTypeDialogComponent } from './report-rooms-by-building-type-dialog.component'
import { ReportNewComponent } from './report-new.component'
import { ReportRoomsWithMaterialsDialogComponent } from './report-rooms-with-materials-dialog.component'
import { ReportDeleteAllPopupComponent } from './report-delete-all-dialog.component'
import { ReportMaterialsByBuildingTypeDialogComponent } from './report-materials-by-building-type-dialog.component'
import { ReportProductsByManufacturerDialogComponent } from './report-products-by-manufacturer-dialog.component'
import { ReportMaterialVariantsDialogComponent } from './report-material-variants-dialog.component'
import { ReportMaterialsAndRoomsWithoutImagesDialogComponent } from '@app/admin/reports/report-materials-and-rooms-without-images-dialog.component'
import { ReportMaterialsByMaterialCategoryDialogComponent } from './report-materials-by-material-category-dialog.component'
import { ReportMaterialsByComponentTypeEqualsExtendedComponent } from './report-materials-by-component-type-equals-extended-dialog'

const reportPageTitle = 'kstandards.report.home.title'

export const reportRoute: Routes = [
    {
        path: 'report-new',
        component: ReportNewComponent,
        data: {
            authorities: Roles.EDITOR_AND_PUBLISHER,
            pageTitle: reportPageTitle
        }
    },
    {
        path: 'report-rooms-by-building-type',
        component: ReportRoomsByBuildingTypeDialogComponent,
        data: {
            authorities: Roles.EDITOR_AND_PUBLISHER,
            pageTitle: reportPageTitle
        }
    },
    {
        path: 'report-rooms-with-materials',
        component: ReportRoomsWithMaterialsDialogComponent,
        data: {
            authorities: Roles.EDITOR_AND_PUBLISHER,
            pageTitle: reportPageTitle
        }
    },
    {
        path: 'report-products-by-manufacturer',
        component: ReportProductsByManufacturerDialogComponent,
        data: {
            authorities: Roles.EDITOR_AND_PUBLISHER,
            pageTitle: reportPageTitle
        }
    },
    {
        path: 'report-materials-by-building-type',
        component: ReportMaterialsByBuildingTypeDialogComponent,
        data: {
            authorities: Roles.EDITOR_AND_PUBLISHER,
            pageTitle: reportPageTitle
        }
    },
    {
        path: 'report-material-variants',
        component: ReportMaterialVariantsDialogComponent,
        data: {
            authorities: Roles.EDITOR_AND_PUBLISHER,
            pageTitle: reportPageTitle
        }
    },
    {
        path: 'report-materials-and-rooms-without-images',
        component: ReportMaterialsAndRoomsWithoutImagesDialogComponent,
        data: {
            authorities: Roles.EDITOR_AND_PUBLISHER,
            pageTitle: reportPageTitle
        }
    },
    {
        path: 'report-materials-by-material-category',
        component: ReportMaterialsByMaterialCategoryDialogComponent,
        data: {
            authorities: Roles.EDITOR_AND_PUBLISHER,
            pageTitle: reportPageTitle,
        },
    },
    {
        path: 'report-materials-by-component-type-equals-extended-dialog',
        component: ReportMaterialsByComponentTypeEqualsExtendedComponent,
        data: {
            authorities: Roles.EDITOR_AND_PUBLISHER,
            pageTitle: reportPageTitle,
        },
    },
    {
        path: 'report/materialsByBuildingType/:id/edit',
        component: ReportMaterialsByBuildingTypeDialogComponent,
        data: {
            authorities: Roles.EDITOR_AND_PUBLISHER,
            pageTitle: reportPageTitle,
        },
    },
    {
        path: 'report/productsByManufacturer/:id/edit',
        component: ReportProductsByManufacturerDialogComponent,
        data: {
            authorities: Roles.EDITOR_AND_PUBLISHER,
            pageTitle: reportPageTitle
        }
    },
    {
        path: 'report/roomsByBuildingType/:id/edit',
        component: ReportRoomsByBuildingTypeDialogComponent,
        data: {
            authorities: Roles.EDITOR_AND_PUBLISHER,
            pageTitle: reportPageTitle,
        },
    },
    {
        path: 'report/roomsWithMaterials/:id/edit',
        component: ReportRoomsWithMaterialsDialogComponent,
        data: {
            authorities: Roles.EDITOR_AND_PUBLISHER,
            pageTitle: reportPageTitle
        }
    },
    {
        path: 'report/materialVariants/:id/edit',
        component: ReportMaterialVariantsDialogComponent,
        data: {
            authorities: Roles.EDITOR_AND_PUBLISHER,
            pageTitle: reportPageTitle
        }
    },
    {
        path: 'report/materialsAndRoomsWithoutImages/:id/edit',
        component: ReportMaterialsAndRoomsWithoutImagesDialogComponent,
        data: {
            authorities: Roles.EDITOR_AND_PUBLISHER,
            pageTitle: reportPageTitle,
        },
    },
    {
        path: 'report/materialsByMaterialCategory/:id/edit',
        component: ReportMaterialsByMaterialCategoryDialogComponent,
        data: {
            authorities: Roles.EDITOR_AND_PUBLISHER,
            pageTitle: reportPageTitle,
        },
    },
    {
        path: 'report/materialsByComponentTypeEqualsExtended/:id/edit',
        component: ReportMaterialsByComponentTypeEqualsExtendedComponent,
        data: {
            authorities: Roles.EDITOR_AND_PUBLISHER,
            pageTitle: reportPageTitle,
        },
    },
]

export const reportsPopUpRoute = [
    {
        path: 'report/:id/delete',
        component: ReportDeletePopupComponent,
        data: {
            authorities: Roles.EDITOR_AND_PUBLISHER,
            pageTitle: reportPageTitle
        },
        canActivate: [UserRouteAccessService],
        outlet: 'popup'
    },
    {
        path: 'report/:id/reportType/:reportType/delete',
        component: ReportDeleteAllPopupComponent,
        data: {
            authorities: Roles.EDITOR_AND_PUBLISHER,
            pageTitle: reportPageTitle
        },
        canActivate: [UserRouteAccessService],
        outlet: 'popup'
    }
]
