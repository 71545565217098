import { BaseEntity } from '../common'
import { Product } from '../product/product.model'
import { File } from '../file/file.model'
import { Country } from '../country'

export class Manufacturer implements BaseEntity {
    constructor(
        public id?: number,
        public name?: string,
        public description?: string,
        public products?: Product[],
        public files?: File[],
        public createCountry?: Country,
        public createdAt?: string,
        public updatedAt?: string,
        public deletedAt?: string
    ) {
    }
}
