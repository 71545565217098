import { Component, forwardRef, Input } from '@angular/core'
import { NG_VALUE_ACCESSOR } from '@angular/forms'
import { CountryIdEnum, StateStorageService } from '@app/auth'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { SingleContentEditComponent } from './single-content-edit.component'

@Component({
    selector: 'single-content-edit-compact',
    styleUrls: ['single-content-edit.component.scss'],
    templateUrl: 'single-content-edit-compact.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SingleContentEditCompactComponent),
            multi: true
        }
    ]
})
export class SingleContentEditCompactComponent extends SingleContentEditComponent {
    public CountryIdEnum = CountryIdEnum

    @Input()
    inlineAttachmentPicker = true

    @Input()
    readonly: boolean

    @Input()
    hasEditPermissions = true

    @Input()
    defaultLocale = ''

    public constructor(
        readonly modalService: NgbModal,
        readonly stateStorageService: StateStorageService
    ) {
        super(modalService, stateStorageService)
    }

    getTranslationsForLanguages() {
        try {
            if (!this.userCountryCode || this.userCountryCode === CountryIdEnum.International) {
                return this.content.translations.filter((translation) => this.languageIds.has(translation.language.id))
            } else {
                return this.content.translations.filter((translation) => this.languageIds.has(translation.language.id) &&
                    (translation.language.locale.split('-')[1].toLocaleLowerCase().includes(this.userCountryCode) || translation.language.locale === this.defaultLocale))
            }
        } catch (error) {
            console.error(error)
            console.error('Possibly one language locale has wrong format')
        }
    }
}
