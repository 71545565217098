import { Component, Input } from '@angular/core';
import { BaseEntity } from '@app/data-access';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'jhi-delete-dialog',
    templateUrl: './delete-dialog.component.html'
})
export class DeleteDialogComponent {
    @Input() data: BaseEntity;
    @Input() message: string;

    constructor(public activeModal: NgbActiveModal) { }

    public cancel(): void {
        this.activeModal.dismiss()
    }

    public confirmDelete(id: number): void {
        this.activeModal.close(id);
    }
}